// External Imports
import { useRef, useState, useEffect, useContext } from "react";
import { useSearchParams } from "react-router-dom";

// Internal Imports
import { DataContext } from "../../context/data-context";
import { requestAdminAPI } from "../../api/posh-api";
import LoadingSpinner from "../UI/LoadingSpinner";
import Heading from "../UI/Heading";
import { UtilityContext } from "../../context/util-context";
import DropDownSearch from "../UI/DropDownSearch";
import { truncate } from "../../util/format-text";

/** ========================================================================
 * * Add Booking Page
 * Renders a page, which allows the admin to create a new booking.
 * ========================================================================== */

export default function AddBooking() {
  // Load Context
  const { vehicles, customers } = useContext(DataContext);
  const { navigate } = useContext(UtilityContext);

  /*
   * Customer and Vehicle IDs can be provided in the URL query params,
   * which allows auto-filling the form.
   */
  // Find customer provided in the URL query params
  const [searchParams] = useSearchParams();
  const customerId = searchParams.get("customerId");
  let selectedCustomer;
  if (customerId) {
    selectedCustomer = customers.filter(
      (customer) => customer.id === customerId
    )[0];
  }
  // Find vehicle provided in the URL query params
  const vehicleId = searchParams.get("vehicleId");
  let selectedVehicle;
  if (vehicleId) {
    selectedVehicle = vehicles.filter((vehicle) => vehicle.id === vehicleId)[0];
  }

  // Initialize states
  const [loading, setLoading] = useState(false);
  const [warning, setWarning] = useState();
  const [customer, setCustomer] = useState(customers[0]);
  const [showRecurringCharge, setShowRecurringCharge] = useState(false);
  const [chargeCustomer, setChargeCustomer] = useState("now");

  /* 
  * Declare refs 
  Refs are used to updated values in the form fields.
  */
  const bookingFeeRef = useRef();
  const deliveryFeeRef = useRef();
  const taxesRef = useRef();
  const totalFeeRef = useRef();

  /* ======================= CALCULATE TOTAL FEE ======================= */
  const calculateTotalFee = () => {
    const totalFee =
      Number(bookingFeeRef.current.value) +
      Number(deliveryFeeRef.current.value) +
      Number(taxesRef.current.value);
    totalFeeRef.current.value = "$" + totalFee.toString();
  };

  /* ======================= UPDATE SELECTED CUSTOMER ======================= */
  const selectCustomer = (e) => {
    setCustomer(
      customers.filter((customer) => customer.id === e.target.value)[0]
    );
  };

  /* ======================= UPDATE SELECTED VEHICLE ======================= */
  const selectVehicle = async (id) => {
    try {
      setWarning(false);
      const vehicle = vehicles.filter((vehicle) => vehicle.id === id)[0];

      // Update the form fields with the vehicle's data
      document.getElementsByName("pick_up_location")[0].value = vehicle.address;
      document.getElementsByName("drop_off_location")[0].value =
        vehicle.address;

      /*
       * Vehicle report includes mileage and fuel level,
       * which is auto-filled in the form fields.
       */
      const report = await requestAdminAPI("fetch-vehicle-reports", {
        vehicles: [{ id: id }],
      });

      // Update the form fields with the vehicle's report data
      if (report) {
        // Update mileage
        document.getElementsByName("start_mileage")[0].value =
          report[0].mileage;
        // Determine fuel level
        let fuel = 0;
        switch (report[0].fuel) {
          case report[0].fuel > 25:
            fuel = 25;
          case report[0].fuel > 50:
            fuel = 50;
          case report[0].fuel > 75:
            fuel = 75;
          case report[0].fuel > 90:
            fuel = 90;
          default:
            fuel = 0;
        }
        // Update fuel level
        document.getElementsByName("start_fuel")[0].value = fuel;
      }
    } catch (err) {
      setWarning(err.message);
      // Reset the form fields
      document.getElementsByName("start_mileage")[0].value = undefined;
      document.getElementsByName("start_fuel")[0].value = undefined;
    }
  };

  /* ======================= CREATE BOOKING HANDLER ======================= */
  const createBookingHandler = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      const payload = {
        customerId: e.target.customer.value,
        vehicleId: e.target.vehicle.value,
        start_date: e.target.start_date.value,
        end_date: e.target.end_date.value,
        start_time: e.target.start_time.value + ":00",
        end_time: e.target.end_time.value + ":00",
        pick_up_location: e.target.pick_up_location.value,
        drop_off_location: e.target.drop_off_location.value,
        start_mileage: e.target.start_mileage.value || 0,
        start_fuel: e.target.start_fuel.value || 90,
        miles_included: e.target.miles_included.value || 800,
        mileage_rate: e.target.mileage_rate.value || 0.35,
        delivery_fee: e.target.delivery_fee.value || "0",
        booking_fee: e.target.booking_fee.value || "0",
        taxes: e.target.taxes.value || "0",
        recurring: e.target.type.value === "true",
        frequency: showRecurringCharge
          ? e.target.recurring_charge.value
          : false,
        charge: e.target.charge.value,
        days_until_due:
          chargeCustomer === "invoice" ? e.target.days_until_due.value : false,
        payment_method_id:
          customer.default_payment_method && chargeCustomer === "now"
            ? e.target.payment_method_id.value
            : false,
        status: chargeCustomer === "none" ? e.target.status.value : false,
      };

      // Send API request
      const response = await requestAdminAPI("create-booking", payload);

      // Navigate to the booking info page
      navigate(`/bookings/info?id=${response.bookingId}`);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setWarning(err.message);
      setLoading(false);
    }
  };

  // Set Customer to the one provided in the URL query params
  useEffect(() => {
    if (selectedCustomer) setCustomer(selectedCustomer);
  }, [selectedCustomer, setCustomer]);

  // Set Vehicle to the one provided in the URL query params
  useEffect(() => {
    if (selectedVehicle) selectVehicle(selectedVehicle.id);
  }, [selectedVehicle]);

  return (
    <div className="container">
      <Heading
        title="Add Booking"
        text="Selecting a vehicle will update fuel and mileage if a tracker is installed. Selecting a customer will updated available payment methods."
      />

      <form onSubmit={createBookingHandler} className="grid gap-8 mt-6">
        <div className="grid text-sm md:grid-cols-4 gap-x-8 gap-y-4 text-stone-400">
          <DropDownSearch
            title="VEHICLE"
            options={vehicles}
            selectHandler={selectVehicle}
            nameFormatter={(v) => `${v.make} ${v.model} ${v.year} ${v.license}`}
          />
          <div className="grid">
            <label className="text-sm">CUSTOMER:</label>
            <select
              onChange={selectCustomer}
              className="form-input"
              name="customer"
              defaultValue={selectedCustomer ? selectedCustomer.id : ""}
            >
              {customers.map((customer) => (
                <option value={customer.id} key={customer.id}>
                  {truncate(`${customer.firstName} ${customer.lastName}`, 30)}
                </option>
              ))}
            </select>
          </div>
          <div className="grid">
            <label className="text-sm">TYPE:</label>
            <select
              onChange={(e) =>
                setShowRecurringCharge(e.target.value === "true")
              }
              className="form-input"
              name="type"
              required
            >
              <option value="false">One Time</option>
            </select>
          </div>
          <div className="grid">
            <label className="text-sm">START DATE:</label>
            <input
              className="form-input"
              name="start_date"
              type="date"
              required
              placeholder="YYYY-MM-DD"
            />
          </div>
          <div className="grid">
            <label className="text-sm">START TIME:</label>
            <input
              className="form-input"
              name="start_time"
              type="time"
              required
              placeholder="YYYY-MM-DD"
            />
          </div>
          <div className="grid">
            <label className="text-sm">END DATE:</label>
            <input
              className="form-input"
              name="end_date"
              type="date"
              required
              placeholder="HH:MM"
            />
          </div>
          <div className="grid">
            <label className="text-sm">END TIME:</label>
            <input
              className="form-input"
              name="end_time"
              type="time"
              required
              placeholder="HH:MM"
            />
          </div>
          <div className="grid md:col-span-2">
            <label className="text-sm">PICK UP LOCATION:</label>
            <input
              className="form-input"
              name="pick_up_location"
              placeholder="Street Address, City, State, Zip"
              type="text"
              required
            />
          </div>
          <div className="grid md:col-span-2">
            <label className="text-sm">DROP OFF LOCATION:</label>
            <input
              className="form-input"
              name="drop_off_location"
              placeholder="Street Address, City, State, Zip"
              type="text"
            />
          </div>
          <div className="grid">
            <label className="text-sm">START MILEAGE:</label>
            <input
              className="form-input"
              name="start_mileage"
              placeholder="Default 0"
              type="number"
            />
          </div>
          <div className="grid">
            <label className="text-sm">START FUEL:</label>
            <select className="form-input" name="start_fuel">
              <option value={90}>&gt; 90%</option>
              <option value={75}>&gt; 75%</option>
              <option value={50}>&gt; 50%</option>
              <option value={25}>&gt; 25%</option>
              <option value={0}>&lt; 25%</option>
            </select>
          </div>
          <div className="grid">
            <label className="text-sm">MILES INCLUDED:</label>
            <input
              className="form-input"
              name="miles_included"
              placeholder="Default 800"
            />
          </div>
          <div className="grid">
            <label className="text-sm">EXTRA MILEAGE:</label>
            <input
              className="form-input"
              name="mileage_rate"
              placeholder="Default $0.35/mi"
            />
          </div>
          <div className="grid">
            <label className="text-sm">BOOKING FEE:</label>
            <input
              ref={bookingFeeRef}
              onChange={calculateTotalFee}
              className="form-input"
              name="booking_fee"
              type="number"
            />
          </div>
          <div className="grid">
            <label className="text-sm">DELIVERY FEE:</label>
            <input
              ref={deliveryFeeRef}
              onChange={calculateTotalFee}
              className="form-input"
              name="delivery_fee"
              type="number"
            />
          </div>
          <div className="grid">
            <label className="text-sm">TAXES:</label>
            <input
              ref={taxesRef}
              onChange={calculateTotalFee}
              className="form-input"
              name="taxes"
              type="number"
            />
          </div>
          <div className="grid">
            <label className="text-sm">TOTAL:</label>
            <input
              ref={totalFeeRef}
              className="form-input-disabled"
              name="total_fee"
              disabled
            />
          </div>
          <div className="grid">
            <label className="text-sm">CHARGE CUSTOMER:</label>
            <select
              onChange={(e) => setChargeCustomer(e.target.value)}
              className="form-input"
              name="charge"
            >
              <option value={"now"}>Now</option>
              <option value={"invoice"}>Send Invoice</option>
              <option value={"none"}>Do Not Charge</option>
            </select>
          </div>
          {chargeCustomer === "now" && (
            <div className="grid">
              <label className="text-sm">PAYMENT METHOD:</label>
              {customer?.default_payment_method ? (
                <select className="form-input" name="payment_method_id">
                  <option value={customer.default_payment_method.id}>
                    {customer.default_payment_method.brand}{" "}
                    {customer.default_payment_method.last4}
                  </option>
                  {customer.payment_methods &&
                    customer.payment_methods.map((pm, idx) => (
                      <option value={pm.id} key={idx}>
                        {pm.brand} {pm.last4}
                      </option>
                    ))}
                </select>
              ) : (
                <input
                  className="form-input-disabled"
                  placeholder="No Payment Methods Attached"
                  disabled
                />
              )}
            </div>
          )}
          {chargeCustomer === "invoice" && (
            <div className="grid">
              <label className="text-sm">DAYS UNTIL DUE:</label>
              <input
                type="number"
                name="days_until_due"
                className="form-input"
                required
                defaultValue="7"
                placeholder="7"
              />
            </div>
          )}
          {chargeCustomer === "none" && (
            <div className="grid">
              <label className="text-sm">BOOKING STATUS:</label>
              <select className="form-input" name="status">
                <option value="confirmed">Confirmed</option>
                <option value="active">Active</option>
                <option value="completed">Completed</option>
                <option value="cancelled">Cancelled</option>
              </select>
            </div>
          )}
        </div>
        <div className="flex items-center space-x-4">
          {loading ? (
            <div className="w-32 btn-loading">
              <LoadingSpinner classes="w-6 h-6 text-white" />
            </div>
          ) : (
            <button className="w-full md:w-32 btn-primary">Submit</button>
          )}
          {warning && <p className="text-sm text-red-600">{warning}</p>}
        </div>
      </form>
    </div>
  );
}
