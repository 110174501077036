// External Imports
import { useContext, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";

// Internal Imports
import { AuthContext } from "../../context/auth-context";
import { startBooking } from "../../api/add";
import uploadFile from "../../util/upload-file";
import DetailPair from "../UI/DetailPair";
import Heading from "../UI/Heading";
import ImageCarousel from "../UI/ImageCarousel";
import LoadingSpinner from "../UI/LoadingSpinner";

export default function CheckOutForm({ setOpen, booking, onComplete }) {
  const { user } = useContext(AuthContext);

  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [warning, setWarning] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [item, setItem] = useState();

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);

      // Get Image URLs
      const imageUrls = [];
      if (images.length > 0) {
        const path = `bookings/${booking.id}/vehicle_stats_image_`;
        await Promise.all(
          images.map(async (image, index) => {
            setItem(index + 1);
            const url = await await uploadFile(
              path + index.toLocaleString(),
              image,
              setUploading
            );
            imageUrls.push(url);
          })
        );
      }

      // Get Data
      const payload = {
        bookingId: booking.id,
        start_mileage: e.target["Start Mileage"].value,
        start_fuel: e.target["Start Fuel"].value,
        status: e.target.status.value,
        check_in_images: imageUrls,
      };

      await startBooking(user, payload);
      setUploading(false);
      setLoading(false);
      onComplete();
    } catch (err) {
      console.log(err);
      setLoading(false);
      setUploading(false);
      setWarning(err.message);
    }
  };

  return (
    <div className="container">
      <Heading title="Check In">
        <AiOutlineClose onClick={() => setOpen(false)} className="icon" />
      </Heading>

      <form onSubmit={submitHandler} className="grid gap-4">
        <div className="info">
          <DetailPair
            label="Start Mileage"
            value={booking.start_mileage}
            edit={true}
          />
          <div className="grid">
            <label className="text-sm text-stone-400">Start Fuel:</label>
            <select className="form-input" name="Start Fuel">
              <option value={90}>&gt; 90%</option>
              <option value={75}>&gt; 75%</option>
              <option value={50}>&gt; 50%</option>
              <option value={25}>&gt; 25%</option>
              <option value={0}>&lt; 25%</option>
            </select>
          </div>
          <div className="grid">
            <label className="text-sm text-stone-400">Booking Status:</label>
            <select className="form-input" name="status">
              <option value="confirmed">Await Customer Check In</option>
              <option value="active">Active (Skip Agreement)</option>
            </select>
          </div>
        </div>

        <p className="text-sm text-stone-400">Images:</p>
        <ImageCarousel images={images} setImages={setImages} preview={true} />

        {loading ? (
          <LoadingSpinner />
        ) : (
          <button className="btn-primary w-fit" type="submit">
            Submit
          </button>
        )}
        {warning && <p className="text-sm text-red-600">{warning}</p>}
        {uploading && (
        <p className="text-sm text-stone-400">
          Uploading Image {item}... {uploading}%
        </p>
        )}
      </form>
    </div>
  );
}
