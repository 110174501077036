import { useState, useContext } from "react";
import { useSearchParams } from "react-router-dom";
import { sendControlSignal } from "../../api/fetch";
import { AuthContext } from "../../context/auth-context";
import LoadingSpinnerWrapper from "../UI/LoadingSpinnerWrapper";

export default function ControlVehicle() {
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const controlVehicle = async (action) => {
    try {
      setLoading(true);
      const response = await sendControlSignal(user, id, action);
      if (action === "location") {
        // Create Google Maps Link from Longitude and Latitude
        const { longitude, latitude } = response;
        const googleMapsLink = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
        setResult({ link: googleMapsLink });
      } else {
        setResult(response);
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  if (loading)
    return (
      <div className="w-full h-full p-8 overflow-scroll rounded-xl bg-stone-900">
        <LoadingSpinnerWrapper />
      </div>
    );

  return (
    <div className="w-full h-full p-8 overflow-scroll rounded-xl bg-stone-900">
      <div className="flex items-center justify-between mb-4">
        <p className="text-lg font-semibold text-white">Control Vehicle</p>
        <div className="flex items-center space-x-6"></div>
      </div>
      <div className="flex my-10 space-x-4">
        <p className="text-stone-400">Response:</p>
        {result?.status && <p className="text-yellow-600">{result.status}</p>}
        {result?.link && (
          <a
            href={result.link}
            target="_blank"
            rel="noreferrer"
            className="text-yellow-600 cursor-pointer"
          >
            Open in Google Maps
          </a>
        )}
      </div>
      <div className="grid gap-4 md:grid-cols-8">
        <button
          onClick={() => controlVehicle("lock")}
          className="btn-secondary"
        >
          Lock
        </button>
        <button
          onClick={() => controlVehicle("unlock")}
          className="btn-secondary"
        >
          Unlock
        </button>
        <button
          onClick={() => controlVehicle("location")}
          className="btn-secondary"
        >
          Location
        </button>
      </div>
    </div>
  );
}
