export default function LinkPair({ label, link, edit, id, type, value, required }) {
  return (
    <div className="cursor-pointer">
      <p className="text-stone-400">{label}:</p>

      {!edit &&
        (link ? (
          <a href={link} target="_blank" className="text-yellow-600 underline">
            {value || "Link"}
          </a>
        ) : (
          <p className="text-stone-200">-</p>
        ))}
      {edit &&
        <input className="form-input" type={type || "file"} id={id || label} required={required}/>}
    </div>
  );
}
