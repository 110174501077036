// External Packages
import { useContext, useEffect, useState } from "react";

// Internal Packages
import { AuthContext } from "../../context/auth-context";

// External Components
import { FiExternalLink } from "react-icons/fi";
import { MdRefresh } from "react-icons/md";

// Internal Components
import { requestAdminAPI } from "../../api/posh-api";
import DetailPair from "../UI/DetailPair";
import Heading from "../UI/Heading";
import LinkPair from "../UI/LinkPair";
import LoadingSpinnerWrapper from "../UI/LoadingSpinnerWrapper";

export default function VehicleReport({ vehicle }) {
  const { user } = useContext(AuthContext);

  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [warning, setWarning] = useState(null);

  // =================== FETCH SAMSARA INFO ===================
  const fetchReportsHandler = async () => {
    try {
      setWarning();
      setLoading(true);
      const response = await requestAdminAPI("fetch-vehicle-reports", {
        vehicles: [{ id: vehicle.id, vin: vehicle.vin }],
      });
      setData(response[0]);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setWarning(err.message);
      setLoading(false);
    }
  };

  // =================== USE EFFECT ===================
  useEffect(() => {
    if (vehicle) fetchReportsHandler();
  }, [vehicle]);

  return (
    <div className="grid gap-4 mb-4">
      <Heading
        isBackArrow={false}
        title="Vehicle Status"
        text="Information below is collected from either Linxup or Samsara. 
        To get more information, click external link icon on the right of group heading."
      >
        <MdRefresh onClick={fetchReportsHandler} className="text-2xl icon" />
        <a
          href={
            data?.type === "Linxup"
              ? "https://app02.linxup.com/ng/portal/index.html"
              : `https://cloud.samsara.com/o/6005413/devices/${data?.id}/vehicle`
          }
          target="_blank"
          title="View on Linxup"
        >
          <FiExternalLink className="icon" />
        </a>
      </Heading>

      {loading ? (
        <LoadingSpinnerWrapper />
      ) : (
        <div className="info">
          <DetailPair label="Tracker Type" value={data?.type} />
          <DetailPair label="Device Serial" value={data?.id} />
          <DetailPair
            label="Speed"
            value={`${data?.speed ? Math.round(data.speed) + "mph" : "-"}`}
          />
          <LinkPair
            label="Location"
            value={data?.address}
            link={
              data?.longitude &&
              data?.latitude &&
              `https://www.google.com/maps/search/?api=1&query=${data?.latitude},${data?.longitude}`
            }
          />
          <DetailPair
            label="Mileage"
            value={`${data?.mileage ? Math.round(data.mileage) + "mi" : "-"}`}
          />
          <DetailPair
            label="Fuel"
            value={`${data?.fuel ? data.fuel + "%" : "-"}`}
          />
        </div>
      )}
      {warning && <p className="text-sm text-red-600">{warning}</p>}
    </div>
  );
}
